// Importamos las librerías necesarias de React y Redux.
import React, { useState } from "react";
import { connect } from "react-redux";
import { useDispatch } from 'react-redux';
// Importamos la acción para cambiar la pestaña activa.
import { changeTabActive } from '../redux/actions';
// Importamos los iconos de FontAwesome.
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";

// Definimos el componente NavBar que recibe la pestaña activa como prop.
const NavBar = ({ activeTab }) => {
    // Utilizamos el hook useDispatch para obtener la función dispatch de Redux.
    const dispatch = useDispatch();
    // Definimos el estado local linkNav con un array de las pestañas de navegación.
    const [linkNav] = useState(['home', 'servicios', 'proyectos', 'contacto']);
    // Definimos el estado local statusNav para controlar el estado de la navegación (activo o no).
    const [statusNav, changeStatusNav] = useState(null);

    // Función para alternar el estado de la navegación.
    const toggleNav = () => {
        changeStatusNav(statusNav === null ? 'active' : null);
    };

    // Función para cambiar la pestaña activa y alternar la navegación.
    const changeTab = (value) => {
        dispatch(changeTabActive(value));
        toggleNav();
    };

    const openBlog = () => {
        window.open('https://nivel99.com/blog', '_self');
    };

    return (
        <header>
            {/* Logo de la plataforma web */}
            <div className="logo">
                <img src="/logo.png" alt=""/> Plataforma web
            </div>
            {/* Menú de navegación */}
            <nav className={statusNav}>
                {
                // Iteramos sobre linkNav para generar las pestañas de navegación.
                linkNav.map(value => (
                    <span 
                        key={value}
                        className={activeTab === value ? 'active': ''}
                        onClick={() => changeTab(value)}
                    >
                        {value}
                    </span>
                ))
                }
                {/* Pestañas adicionales */}
                <span>Inglés</span>
                <span onClick={openBlog}>Blog</span>
            </nav>
            {/* Icono para alternar la navegación en dispositivos móviles */}
            <div className="icon-bar" onClick={toggleNav}>
                <FontAwesomeIcon icon={faBars} />
            </div>
        </header>
    );
}

// Función para mapear el estado de Redux a las props del componente.
const mapStateToProps = (state) => ({
    activeTab: state.activeTab
});

// Conectamos el componente NavBar con Redux y exportamos.
export default connect(mapStateToProps, { changeTabActive })(NavBar);

// Importamos React y el hook useRef para crear referencias en el DOM.
import React, { useRef } from 'react';
// Importamos un hook personalizado desde un archivo local.
import CustomHook from './CustomHook';

function Home() {
  // Creamos una referencia para el elemento de scroll.
  const scrollTab = useRef();
  // Llamamos al hook personalizado y le pasamos la referencia.
  CustomHook(scrollTab);

  return (
    // Definimos una sección con la referencia creada y una clase CSS para los estilos.
    <section ref={scrollTab} className='home'>
      <div className="content">
        <div className="name">
          {/* Texto de bienvenida con un span para estilizar el nombre del sitio */}
          Bienvenidos a <span>NIVEL 99</span>
        </div>
        <div className="des">
          {/* Descripción del equipo y sus pasiones */}
          Nos apasiona el futuro de la tecnología y la creatividad. Somos un equipo de expertos dedicados a la creación de videojuegos, el desarrollo de inteligencia artificial innovadora y la producción de contenido audiovisual de alta calidad.
        </div>
        
        {/* Enlace para descargar un documento PDF, con configuración para abrir en una nueva pestaña */}
        <a target="_blank" rel="noopener noreferrer" className='animation active'>
          Descargar Metaverso
        </a>
      </div>
      <div className="avatar">
        <div className="card">
          {/* Imagen de avatar */}
          <img src="/avatar.jpg" alt="" />
          <div className="info">
            {/* Información adicional del avatar */}
            <div>Videojuegos</div>
            <div>Web</div>
            <div>Cine Digital</div>
            <div>Red Social</div>
          </div>
        </div>
      </div>
    </section>
  );
}

// Exportamos el componente Home para que pueda ser utilizado en otros archivos.
export default Home;

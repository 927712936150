// Importamos el archivo CSS para los estilos específicos de la aplicación.
import './App.css';
// Importamos los componentes que formarán parte de nuestra aplicación.
import NavBar from './components/NavBar';
import Home from './components/Home';
import Skills from './components/Skills';
import Projects from './components/Projects';
import Contacts from './components/Contacts';

// Definimos el componente principal de la aplicación.
function App() {
  return (
    // Utilizamos el elemento <main> como contenedor principal de la aplicación.
    <main>
      {/* Renderizamos cada uno de los componentes importados en el orden deseado. */}
      <NavBar />
      <Home />
      <Skills />
      <Projects />
      <Contacts />
    </main>
  );
}

// Exportamos el componente App para que pueda ser utilizado en otros archivos.
export default App;

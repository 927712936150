// Importamos las librerías necesarias de React.
import React, { useRef, useState } from 'react' 
// Importamos los iconos de FontAwesome.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReact, faHtml5, faUnity, faJs, faDashcube, faLaravel } from '@fortawesome/free-brands-svg-icons';
// Importamos un hook personalizado desde un archivo local.
import CustomHook from './CustomHook';

function Skills() {
  // Creamos referencias para los elementos del DOM.
  const divs = useRef([]);
  const scrollTab = useRef();

  // Llamamos al hook personalizado y le pasamos las referencias.
  CustomHook(scrollTab, divs);

  // Definimos el estado local listSkills con un array de habilidades.
const [listSkills] = useState([
  {
    name: 'Web 3D',
    des: 'Creamos sitios web dinámicos y también con elementos 3D, haciendo más atractiva tu marca.',
    icon: faHtml5
  },
  {
    name: 'Videojuegos',
    des: 'Creamos videojuegos single player y multiplayer y experiencias virtuales con Unity y Unreal Engine, hacemos experiencias en realidad aumentada.',
    icon: faUnity
  },
  {
    name: 'Creación de microservicios',
    des: 'Creación de microservicios en Nodejs para todo tipo de aplicaciones para startups, emprendimientos y empresas.',
    icon: faJs},
  {
    name: 'ReactJs',
    des: 'Creamos sitios y aplicativos web seguros y escalables con la tecnología de ReactJS o Nextjs, también hacemos paginas 3D con esta misma tecnología y plataformas educativas.',
    icon: faReact
  },
  {
    name: 'Recorridos virtuales',
    des: 'Ideales para su proyecto de arquitectura o mostrar un servicio que tengas en mente o prototipo y quieres que sea interactivo para que tus clientes lo conozcan.',
    icon: faDashcube
  },
  {
    name: 'Laravel',
    des: 'Creación rápida de CRUD y microservicios acordes a las necesidades del proyecto.',
    icon: faLaravel
  }

]);
return (
  // Definimos una sección con la referencia creada y una clase CSS para los estilos.
  <section className='skills servicios' ref={scrollTab}>
    <div className="title" ref={(el) => el && divs.current.push(el)}>
      Servicios
    </div>
    <div className="des" ref={(el) => el && divs.current.push(el)}>
      {/* Descripción general de los servicios */}
      Creación de Videojuegos, Inteligencia Artificial, Producción de Video y creación de sitios web.
    </div>
    <div className="list">
      {
        // Iteramos sobre listSkills para generar la lista de habilidades.
        listSkills.map((value, key) => (
          <div className={'item '} key={key} ref={(el) => el && divs.current.push(el)}>
            <FontAwesomeIcon icon={value.icon} />
            <h3>{value.name}</h3>
            <div className="des">{value.des}</div>
          </div>
        ))
      }
    </div>
  </section>
);
}

export default Skills


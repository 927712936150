// Importamos las librerías de React y ReactDOM para poder trabajar con componentes y renderizar en el DOM.
import React from 'react';
import ReactDOM from 'react-dom/client';
// Importamos el archivo CSS para los estilos globales.
import './index.css';
// Importamos el componente principal de la aplicación.
import App from './App';
// Importamos la función para reportar métricas de rendimiento.
import reportWebVitals from './reportWebVitals';
// Importamos el Provider de react-redux para integrar Redux con React.
import { Provider } from 'react-redux';
// Importamos el store configurado de Redux.
import store from './redux/store';

// Creamos un punto de entrada en el DOM donde se montará nuestra aplicación React.
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderizamos la aplicación envolviéndola en el Provider de Redux para que todos los componentes puedan acceder al store.
root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// Si quieres empezar a medir el rendimiento de tu aplicación, pasa una función
// para registrar los resultados (por ejemplo: reportWebVitals(console.log))
// o envíalos a un endpoint de analítica. Aprende más en: https://bit.ly/CRA-vitals
reportWebVitals();

// Importamos los hooks necesarios de React.
import { useEffect } from 'react';
// Importamos useSelector de react-redux para acceder al estado de Redux.
import { useSelector } from 'react-redux';

// Definimos el hook personalizado.
const CustomHook = (refTab = null, refList = null) => {
  // Referencias a los elementos del DOM.
  const scrollTab = refTab;
  const divs = refList;
  
  // Obtenemos el estado activeTab desde Redux.
  const activeTab = useSelector(state => state.activeTab);

  // useEffect se ejecuta cada vez que activeTab cambia.
  useEffect(() => {
    // Si la clase del elemento scrollTab contiene la pestaña activa.
    if (scrollTab.current.classList.contains(activeTab)) {
      const componentNode = scrollTab.current;
      // Desplazamos el elemento a la vista de manera suave.
      componentNode.scrollIntoView({ behavior: 'smooth' });
    }

    // Si divs no es nulo.
    if (divs !== null) {
      // Añadimos la clase 'animation' a cada div.
      divs.current.forEach((div) => {
        div.classList.add('animation');
      });

      // Definimos una función para manejar el scroll.
      const handleScroll = () => {
        const scrollPosition = window.scrollY;
        divs.current.forEach((div) => {
          const offsetTop = div.getBoundingClientRect().top + scrollPosition;
          // Añadimos o removemos la clase 'active' dependiendo de la posición del scroll.
          if (scrollPosition >= offsetTop - (window.innerHeight / 1.5)) {
            div.classList.add('active');
          } else {
            div.classList.remove('active');
          }
        });
      };

      // Añadimos el evento de scroll al window.
      window.addEventListener('scroll', handleScroll);

      // Cleanup function para remover el evento de scroll cuando el componente se desmonte.
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }
  }, [activeTab]); // Dependencia del efecto.

};

export default CustomHook;

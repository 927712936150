// Importamos las librerías necesarias de React.
import React, { useState, useRef } from 'react';
// Importamos los iconos de FontAwesome.
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonCircleQuestion, faEarthAmericas } from '@fortawesome/free-solid-svg-icons';
// Importamos un hook personalizado desde un archivo local.
import CustomHook from './CustomHook';

function Projects() {
  // Definimos el estado local listProjects con un array de proyectos.
  const [listProjects] = useState([
    {
      name: 'Plataforma de aprendizaje del idioma inglés',
      des: 'Plataforma de aprendizaje del idioma inglés, mediante actividades diarias y mini juegos.',
      mission: 'Enseñar el idioma mas demandado de forma fácil y cómoda a tu propio ritmo.',
      language: 'Fácil de usar, 10 frases diarias, mini juegos y realidad aumentada.',
      images: '/project1.PNG'
    },
    {
      name: 'Micro metaversos',
      des: 'Micro metaversos cada uno con un propósito distinto, desde la educación hasta el entretenimiento general con el fin de potenciar habilidades y dar mas conocimiento a la marca.',
      mission: 'Metaverso multipropósito',
      language: 'HTML5, CSS3, React JS, SockerIO,Unreal Engine, Unity',
      images: '/project2.PNG'
    },
    {
      name: 'Videojuegos para PC, móviles y web',
      des: 'Videojuegos creados con las mejores tecnologías del momento, Unreal Engine, Unity, Godot y Threejs, disponibles para PC, dispositivos móviles y web.',
      mission: 'Videojuegos buenos creados en tiempos récord',
      language: 'Unreal Engine, Unity, Godot, ThreeJS',
      images: '/project2.PNG'
    },
  ]);

  // Creamos referencias para los elementos del DOM.
  const divs = useRef([]);
  const scrollTab = useRef();

  // Llamamos al hook personalizado y le pasamos las referencias.
  CustomHook(scrollTab, divs);

  return (
    // Definimos una sección con la referencia creada y una clase CSS para los estilos.
    <section className='projects proyectos' ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        Estos son mis proyectos actuales
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        {/* Descripción general de los proyectos */}
        Plataforma de aprendizaje del idioma inglés, mediante actividades diarias y mini juegos, Red social y micro metaversos.
      </div>
      <div className="list">
        {
          // Iteramos sobre listProjects para generar la lista de proyectos.
          listProjects.map((value, key) => (
            <div className='item' key={key} ref={(el) => el && divs.current.push(el)}>
              <div className="images">
                <img src={value.images} alt="" />
              </div>
              <div className="content">
                <h3>{value.name}</h3>
                <div className="des">{value.des}</div>
                <div className="mission">
                  <div><FontAwesomeIcon icon={faPersonCircleQuestion} /></div>
                  <div>
                    <h4>Misión</h4>
                    <div className="des">{value.mission}</div>
                  </div>
                </div>
                <div className="mission">
                  <div><FontAwesomeIcon icon={faEarthAmericas} /></div>
                  <div>
                    <h4>Tecnologías</h4>
                    <div className="des">{value.language}</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  );
}

// Exportamos el componente Projects para que pueda ser utilizado en otros archivos.
export default Projects;

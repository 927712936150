// Importamos las librerías necesarias de React.
import React, { useState, useRef } from 'react';
// Importamos un hook personalizado desde un archivo local.
import CustomHook from './CustomHook';

function Contacts() {
  // Definimos el estado local listContacts con un array de contactos.
  const [listContacts] = useState([
    {
      title: 'Email',
      value: 'info@nivel99.com'
    },
    {
      title: 'Facebook',
      value: 'https://www.facebook.com/nivel99interactive'
    },
  ]);

  // Creamos referencias para los elementos del DOM.
  const divs = useRef([]);
  const scrollTab = useRef();

  // Llamamos al hook personalizado y le pasamos las referencias.
  CustomHook(scrollTab, divs);

  return (
    // Definimos una sección con la referencia creada y una clase CSS para los estilos.
    <section className='contacts contacto' ref={scrollTab}>
      <div className="title" ref={(el) => el && divs.current.push(el)}>
        Contacto
      </div>
      <div className="des" ref={(el) => el && divs.current.push(el)}>
        {/* Descripción general de los contactos */}
        Después de enviar el correo electrónico le estaremos respondiendo dentro de las 24 horas
      </div>
      <div className="list" ref={(el) => el && divs.current.push(el)}>
        {
          // Iteramos sobre listContacts para generar la lista de contactos.
          listContacts.map((value, key) => (
            <div className='item' key={key}>
              <h3>{value.title}</h3>
              <div>{value.value}</div>
            </div>
          ))
        }
      </div>
    </section>
  );
}

// Exportamos el componente Contacts para que pueda ser utilizado en otros archivos.
export default Contacts;